html,
body .sidebar {
  height: 100%;
}

body .ant-modal-wrap {
  overflow: hidden;
}

.page-body {
  position: relative;
  overflow: hidden;
}

.ant-tooltip .ant-tooltip-inner {
  border-radius: 4px;
  font-size: 11px;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 20px;
  padding: 2px 6px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.ant-dropdown {
  max-height: 75vh;
}

.ant-modal-confirm-title {
  font-weight: 700;
}

.ant-layout-content::-webkit-scrollbar-track {
  padding-right: 5px;
}

.ant-layout-content::-webkit-scrollbar {
  width: 10px;
  margin-right: 15px;
}

.ant-layout-content::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
  margin-right: 15px;
  border: 2px solid #f9f9f9;
}

.ant-layout-content::-webkit-scrollbar-thumb:hover {
  background: #454b57;
}

.ant-modal-body::-webkit-scrollbar-track {
  padding-right: 5px;
}

.ant-modal-body::-webkit-scrollbar {
  width: 10px;
  margin-right: 15px;
}

.ant-modal-body::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
  margin-right: 15px;
  border: 2px solid #f9f9f9;
}

.ant-modal-body::-webkit-scrollbar-thumb:hover {
  background: #454b57;
}

.ant-modal-body {
  height: auto !important;
}

.ant-table-tbody > tr > td {
  word-break: break-word;
}

.ant-spin-nested-loading {
  width: 100%;
  margin: 0 auto;
}

.flexbox-spinner-wrapper {
  height: 100%;
}

.flexbox-spinner-wrapper .ant-spin-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.grid-spinner-wrapper .ant-spin-container {
  height: 80vh;
  display: grid;
  flex-direction: column;
}

pre {
  word-break: break-word;
  white-space: break-spaces;
}

.joyride-tooltip-content {
  max-height: 50vh; /* Adjust as needed */
  overflow: auto;
  max-width: 50vw;
}

.joyride-content h3 {
  font-size: 1.5em;
}

.joyride-content p {
  font-size: 1em;
  line-height: 1.5;
}

@media (max-width: 640px) {
  .joyride-content h3 {
    font-size: 1.2em;
  }

  .joyride-content p {
    font-size: 0.9em;
    line-height: 1.4;
  }
}
