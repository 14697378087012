body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.__floater__open,
.react-joyride-portal,
.react-joyride__beacon,
.react-joyride-step-0,
.react-joyride__spotlight,
.react-joyride__overlay,
.react-joyride__spotlight {
  z-index: 9999 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
